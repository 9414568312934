import React, { useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const Doughnut = ({ dataColors, reservation }) => {
  const doughnutEChartColors = getChartColorsArray(dataColors);

  // Orders_Detail verilerini işlem
  const productTotals = useMemo(() => {
    const totals = {};

    reservation.forEach(order => {
      order.Orders_Detail.forEach(detail => {
        const productId = detail.Products_ID;
        const productName = detail.Products.Products_Name;
        console.log("productName123",productName)
        if (!totals[productId]) {
          totals[productId] = { value: 0, name: productName };
        }
        totals[productId].value += detail.TotalPrice;
      });
    });

    return Object.values(totals);
  }, [reservation]);

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: productTotals.map(item => item.name),
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: doughnutEChartColors,
    series: [
      {
        name: "Toplam Satış",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "30",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: productTotals,
      },
    ],
  };

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};

export default Doughnut;
