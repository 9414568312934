import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname, Email, GSM,Date,Status } from "./contactlistCol";

import DateModal from "components/Common/DateModal";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";
import FilterModal from "components/Common/FilterModal";

import {
  getSubEducation as onGetSubEducation,
  addSubEducation as onAddSubEducation,
  updateSubEducation as onUpdateSubEducation,
  deleteSubEducation as onDeleteSubEducation,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ExportToExcel2 } from "ExportToExcel2";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Tahsilat | Sıha B2B ADMIN PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getUser, setUser] = useState([]);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);
  const [getFilterModal, setFilterModal] = useState(false);
  const [getFilterData, setFilterData] = useState([]);
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);

  const [getType, setType] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Education_SubItem_Title) || "",
      desc: (contactEdit && contactEdit.Payment_Desc) || "",
      type: (contactEdit && contactEdit.Payment_Type) || "",
      amount: (contactEdit && contactEdit.PaymentTotalAmount) || "",
    },
    validationSchema: Yup.object({
    
    }),
    onSubmit:async (values) => {
      var localDate = moment().local();

      if (isEdit) {
        const [id, limitPrice] = getUserID.split('|');

        const updateUser = {
          ID: contactEdit.ID,
          Payment_Active:true,
          PaymentTotalAmount: values.amount,
          Users_ID: parseInt(id),
         // Payment_Desc:values.desc,
          Payment_Type:values.type,
          Payment_CreatedDateTime : localDate,
        };
        dispatch(onUpdateSubEducation(updateUser));

        

        const patchLimit = {
          Limit_Price : parseFloat(getUserLimit) + values.amount
        }
        await axios.patch(`https://sihaadmin.stechomeyazilim.info:8182/updateUsersLimit/${getUserLimitID}`, patchLimit).then(async (res) => {
       
        })

        setIsEdit(false);
        validation.resetForm();
      } else {

        const [id, limitPrice] = getUserID.split('|');

        const newUser = {
          Payment_Active:true,
          PaymentTotalAmount: values["amount"],
          Users_ID: parseInt(id),
          Payment_CreatedDateTime : localDate,
        //  Payment_Desc:values["desc"],
          Payment_Type:values["type"]
        };
        // save new user

        console.log("newUser123",newUser)
        const patchLimit = {
          Limit_Price : parseFloat(getUserLimit) + values.amount
        }
        await axios.patch(`https://sihaadmin.stechomeyazilim.info:8182/updateUsersLimit/${getUserLimitID}`, patchLimit).then(async (res) => {
       
        })


        dispatch(onAddSubEducation(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.educationSubItem,
  }));
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [getUserID, setUserID] = useState(null);
  const [getUserLimit, setUserLimit] = useState(null);
    const [getUserLimitID, setUserLimitID] = useState(null);
    const [getExcelModal, setExcelModal] = useState(false)
    const [getUserType, setUserType] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "Kul. Adı",
        accessor: "Users.Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Tarih",
        accessor: "Payment_CreatedDateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },
      {
        Header: "Total Ücret",
        accessor: "PaymentTotalAmount",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },
      {
        Header: "Ödeme Tipi",
        accessor: "Payment_Type",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },


    

     {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

{getUserType == 1 ? 
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>: null}
            </div>
          );
        },
      } 
    ],
    []
  );



  useEffect(() => {
    if (users && !users.length) {

      let authUser = JSON.parse(localStorage.getItem("authUser"));

      setUserType(authUser.Users_Type_ID)

      if(authUser.Users_Limit.length > 0){
        setUserLimit(authUser.Users_Limit[0].Limit_Price)
        setUserLimitID(authUser.Users_Limit[0].ID)

      }

      if(authUser.Users_Type_ID == 1){
        dispatch(onGetSubEducation(null))
            
      }else{
         dispatch(onGetSubEducation(authUser.ID));
      }
    
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
   // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };


  const _getAuthData2 = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersDemir/select/`)
      .then((res) => {
        setUser(res.data)
      })
  }


  const _setChoiceUser = arg => {
    
    const [id, limitPrice] = arg.target.value.split('|');

    setUserID(`${id}|${limitPrice}`)

    setUserLimit(`${limitPrice}`)


  }

  useEffect(() => {
    
    _getAuthData2()
    
   
  }, [dispatch, getUser ]);

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    console.log("lşsdlşf",`${user.Users_ID}|${getUserLimit}`)
    setUserID(`${user.Users_ID}|${getUserLimit}`)

    setIsEdit(true);


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteSubEducation(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };


   const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }
  


  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
  

  const exportToCSVLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    console.log("lksdklf")
    let fileName = getSelectDate + getSelectDateFinish

    const data2 = users.filter(x => x.Payment_CreatedDateTime >= getSelectDate && x.Payment_CreatedDateTime <= getSelectDateFinish).map(elt => [
    elt.PaymentTotalAmount,
    elt.Payment_Type,
    elt.Users.Users_NameSurname,
    moment(elt.Payment_CreatedDateTime).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Tutar", t: "s" };
      ws["B1"] = { v: "Ödeme Tipi", t: "s" };
      ws["B1"] = { v: "Kullanıcı", t: "s" };
      ws["C1"] = { v: "Tarih", t: "s" };

      
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }
  const exportToCSVDontLoginLastMonth= () => {


    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "Son1AyGirisYapmayanlar"


    const data2 = users.filter(x => x.Last_Login_Date < getSelectDate || x.Last_Login_Date > getSelectDateFinish).map(elt => [
    elt.NameSurname,
    moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
      ]);

      const ws = XLSX.utils.json_to_sheet(data2);
    
      ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
      ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const onClickExcelLogin = (order) => {
    setExcelModal(true);
    setType('login')
  }

  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 
  }

  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"


    
    const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
      elt.NameSurname,
      elt.Last_Login_Date,
        ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
    ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };


    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }


  const total = getFilterCheckModal == true ? getFilterData.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0) : users.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0);

  return (
    <React.Fragment>

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={getType == "login" ? exportToCSVLoginLastMonth: exportToCSVDontLoginLastMonth}
        show={getExcelModal}
        type={getType}
        onCloseClick={() => setExcelModal(false)}
      />
      

<FilterModal
        handleSelectChangeCompany= {_onFilterClick}
        show={getFilterModal}
        getCategoryCompany={getUser}
        onCloseClick={() => setFilterModal(false)}
     
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Tahsilat Gir" />

          {  getFilterCheckModal == false && getUserType == 1? 
<Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterModal(true)}
              >
                <i className="mdi mdi-plus me-1" />
                Firmaya Göre Filtrele
              </Button>:
            getFilterCheckModal == true && getUserType == 1 ? 
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterCheckModal(false)}
              >
                <i className="mdi mdi-plus me-1" />
                Listeyi Sıfırla
              </Button>  : null}

             {getUserType == 1 ?  <ExportToExcel2 title={"Tarihe Göre Excel"} onDownloadClick={exportToCSVLoginLastMonth} onClickExcel={onClickExcelLogin} apiData={users} fileName={"fileName"} />
 : null}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
      
              
                  <TableContainer
                    columns={columns}
                    data={getFilterCheckModal == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddPayment={getUserType == 1 ? true : false}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />


                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Tahsilat Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                       
                        
                      
                            <div className="mb-3">
                              <Label className="form-label">Tutar</Label>
                              <Input
                                name="amount"
                                label="amount"
                                type="number"
                                placeholder="Tutar Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.amount || ""}
                                invalid={
                                  validation.touched.amount &&
                                    validation.errors.amount
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.amount &&
                                validation.errors.amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          

                            <div className="mb-3">
                          
                                  
                                  <Label className="form-label">Kullanıcı Seçiniz</Label>
                                  <Input
                                    name="paymentStatus"
                                    type="select"
                                    className="form-select"
                                    defaultValue={getUserID}
                                    onChange={(text) => _setChoiceUser(text)}
                                    value={getUserID} // Change this line
                                  >
                                      <option value="">Lütfen Seçim Yapınız</option>
          
                                    {getUser.map((item, index) => (
                                      <option  value={`${item.ID}|${item.Users_Limit.length > 0 ? item.Users_Limit[0].Limit_Price : ''}`} key={item.ID}>{item.Users_NameSurname} - {item.Users_Limit.length > 0 ? item.Users_Limit[0].Limit_Price : null} TL</option>
                                    ))}
          
                                  </Input>
                             
                             
                                              
                    </div>

                

                  <div className="mb-3">
                              <Label className="form-label">Açıklama Text</Label>
                              <Input
                                name="desc"
                                label="desc"
                                type="text"
                                placeholder="Açıklama Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.desc || ""}
                                invalid={
                                  validation.touched.desc &&
                                    validation.errors.desc
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.desc &&
                                validation.errors.desc ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.desc}
                                </FormFeedback>
                              ) : null}
                            </div>
                          

                            <div className="mb-3">
                              <Label className="form-label">Ödeme Türü</Label>
                              <Input
                                name="type"
                                label="type"
                                type="text"
                                placeholder="Ödeme Türü Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.type || ""}
                                invalid={
                                  validation.touched.type &&
                                    validation.errors.type
                                    ? true
                                    : false
                                }
                              />
                               {validation.touched.type &&
                                validation.errors.type ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.type}
                                </FormFeedback>
                              ) : null}
                            </div>


                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
