import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Date } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getTeacherMega  as onGetTeacher,
  addNewUser as onAddNewUser,
  updateTeacher as onUpdateTeacher,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {

  //meta title
  document.title = "Bildirim Listesi | Siha B2B PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [getEditContactTeacher, setEditContactTeacher] = useState();
  const [getIsActive, setIsActive] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEditContactTeacher && getEditContactTeacher.NameSurname) || "",
      gsmnumber: (getEditContactTeacher && getEditContactTeacher.GsmNumber) || "",
      mailadress: (getEditContactTeacher && getEditContactTeacher.MailAdress) || '',
      password: (getEditContactTeacher && getEditContactTeacher.Users_Password) || "",
      registerDate: (getEditContactTeacher && getEditContactTeacher.CreatedDateTime) || "",      
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Adınızı Giriniz"),
      mailadress: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Mail Formatında Giriniz"
      ).required("Lütfen mail adresini giriniz"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          ID: contact.ID,
          NameSurname: values.name,
          GsmNumber: values.gsmnumber,
          MailAdress: values.mailadress,
          Users_Password: values.password,
          Users_IsActive: getIsActive,
          Users_Type_ID:3
        };
        // update user
        dispatch(onUpdateTeacher(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          NameSurname: values["name"],
          GsmNumber: values["gsmnumber"],
          MailAdress: values["mailadress"],
          Users_Password: values["password"],
          Users_IsActive: getIsActive,
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.teacher,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
    
      {
        Header: "Adı Soyadı",
        accessor: "Users.Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Bildirim",
        accessor: "Notification_Text",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
 
      {
        Header: 'Kayıt Tarihi',
        accessor: 'Created_DateTime',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
     
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetTeacher());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    setEditContactTeacher(user)
    setContact(user);
    setIsEdit(true);
    setIsActive(user.Teacher_IsActive)
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.id) {
      dispatch(onDeleteUser(contact.id));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Kullanıcı" breadcrumbItem="Bildirim Listesi" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddTeacher={false}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Bildirim Düzenle" : "Bildirim Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >      

                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Ad-Soyad</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Ad ve Soyad"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">GSM</Label>
                              <Input
                                name="gsmnumber"
                                label="Gsm"
                                type="text"
                                placeholder="GSM Numaranızı Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gsmnumber || ""}
                                invalid={
                                  validation.touched.gsmnumber &&
                                    validation.errors.gsmnumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.gsmnumber &&
                                validation.errors.gsmnumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gsmnumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Mail</Label>
                              <Input
                                name="mailadress"
                                label="Email"
                                disabled={true}
                                type="email"
                                placeholder="Mail Adresini Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mailadress || ""}
                                invalid={
                                  validation.touched.mailadress &&
                                    validation.errors.mailadress
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mailadress &&
                                validation.errors.mailadress ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mailadress}
                                </FormFeedback>
                              ) : null}
                            </div>
                           
                      

                            <div className="mb-3">
                              <Label className="form-label">Şifre</Label>
                              <Input
                                name="passowrd"
                                label="Şifre"
                                type="text"
                                placeholder="Şifrenizi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="square-switch">

                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>

                            <div className="mb-3">
                          
                          <Label className="form-label">Kayıt Tarihi</Label>

                    <input
                      className="form-control"
                      type="date"
                      disabled={true}
                     // value={contactEdit && moment(contactEdit.Users_Birthday).format('yyyy-mm-DD')}
                      defaultValue={moment(validation.values.registerDate).format('yyyy-MM-DD')}
                      id="example-date-input"
                    />
                 
                </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
