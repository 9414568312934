import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";


// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import Slidewithfade from "./CarouselTypes/slidewithfade";




import {

  getUsers as onGetUsers,

  getReservation as onGetReservation,
  getSubEducation as onGetSubEducation,
} from "store/contacts/actions";
const Dashboard = props => {

  const dispatch = useDispatch();
  const [getUserType, setUserType] = useState(null);
  const [getUserLimit, setUserLimit] = useState(null);
    const [getUserID, setUserID] = useState(null);

  const { reservation } = useSelector(state => ({
    reservation: state.contacts.reservation,
  }));

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  const { educationSubItem } = useSelector(state => ({
    educationSubItem: state.contacts.educationSubItem,
  }));

  const totalPaymentAmount = educationSubItem.reduce((total, item) => total + item.PaymentTotalAmount, 0);

  const reports = [
    { title: "Toplam Sipariş", iconClass: "bx-archive-in", description: reservation.length },
    {
      title: getUserType == 1 ? "Toplam Üyeler" : "Toplam Limit",
      iconClass: "bx-purchase-tag-alt",
      description:  getUserType == 1 ?users.length : getUserLimit +  " TL" ,
    },
    { title: getUserType == 1 ? "Toplam Tahsilat Sayısı" : "Yapılan Ödeme", iconClass: "bx-archive-in", description: educationSubItem.length },
    { title: getUserType == 1 ? "Toplam Tahsilat Tutarı" : "Toplam Ödenen Tutar", iconClass: "bx-archive-in", description: totalPaymentAmount + " TL"},

  ];

  useEffect(() => {
    if (educationSubItem && !educationSubItem.length) {
      let authUser = JSON.parse(localStorage.getItem("authUser"));

      if(authUser.Users_Type_ID == 1){
        dispatch(onGetSubEducation());

      }else{
        dispatch(onGetSubEducation(authUser.ID));

      }

    }
  }, [dispatch, users]);


  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (reservation && !reservation.length) {

      let authUser = JSON.parse(localStorage.getItem("authUser"));


      setUserType(authUser.Users_Type_ID)
      if(authUser.Users_Limit.length > 0){
        setUserLimit(authUser.Users_Limit[0].Limit_Price)
      }
      setUserID(authUser.ID)


      if(authUser.Users_Type_ID == 1){
        dispatch(onGetReservation(null))
            }else{
         dispatch(onGetReservation(authUser.ID));
      }
      
    }
  }, [dispatch, reservation]);



  //meta title
  document.title = "Anasayfa | Siha B2B Paneli";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Yönetim")}
            breadcrumbItem={props.t("Anasayfa")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>

                 
                    
                         

                  </Col>
                ))}
              </Row>

          
           
             
            </Col>

           



            <div className="text-center align-self-center  justify-center items-center" >
            <Slidewithfade />
            </div>
          </Row>

         
        </Container>
      </div>

   
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
