import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Button
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname,Date } from "./contactlistCol";
import DateModal from "components/Common/DateModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";


import Dropzone from 'react-dropzone-uploader';
import {ExportToExcel2} from '../../../ExportToExcel2'

import {
  getReservation as onGetReservation,
  addReservation as onAddProduct,
  updateReservation as onUpdateProduct,
  deleteReservation as onDeleteReservation,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import classnames from "classnames";
import FilterModal from "components/Common/FilterModal";
import { useNavigate } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import toastr from "toastr";
import "toastr/build/toastr.min.css";


const ContactsList = props => {
  const tableRef = useRef(null);
  const navigate = useNavigate();

  //meta title
  document.title = "Siparişler | Siha B2B PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();
  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getExcelModal, setExcelModal] = useState(false)

  const [passedSteps, setPassedSteps] = useState([1]);

  
  const submitAdd = async(tab) => {
    var localDate = moment().local();
    
    const [id, limitPrice] = getUserID.split('|');


      if (getPaymentID == 2) {
        console.log("msdşlf1123",getPaymentID)


         navigate('/payment', {
            state: {
                totalCashPrice: totalCashPrice,
                getUserType: getUserType,
                getUserID: getUserID,
                getPaymentID: getPaymentID,
                localDate: localDate,
                getIsActive: getIsActive,
                getCategory: getCategory,
                id: id
            },
          }); 

          const state = {
            totalCashPrice: totalCashPrice,
            getUserType: getUserType,
            getUserID: getUserID,
            getPaymentID: getPaymentID,
            localDate: localDate,
            getIsActive: getIsActive,
            getCategory: getCategory,
            id: id,
            getUserLimit,getUserLimitID
        };
        localStorage.setItem('paymentState', JSON.stringify(state));
       

    }

    else{

      console.log("sldkmfş",getUserLimit,getUserLimitID)

      const patchLimit = {
        Limit_Price : parseFloat(getUserLimit) - parseFloat(totalCashPrice)
      }
      await axios.patch(`https://sihaadmin.stechomeyazilim.info:8182/updateUsersLimit/${getUserLimitID}`, patchLimit).then(async (res) => {
     
      })

    const newOrder = {
      Orders_TotalPrice:totalCashPrice,
      Users_ID:getUserType == 1 ? id : getUserID,
      Payment_Select_ID:getPaymentID,
      Created_DateTime:localDate,
      Orders_IsActive:getIsActive,
     // Payment_Type:getPaymentID.toString()
    };
    await axios.post(`https://sihaadmin.stechomeyazilim.info:8182/addOrderDemir/send`, newOrder).then(async (res) => {

      if(res.data.value.length>0){
        getSelectedValuesProduct.map(async (item, index) => {
          const selectedItem = getCategory.find(item2 => item2.ID == item);
    
          const newDetail = {
            TotalPrice:quantities[item] * parseInt(selectedItem.Product_CashPrice),
            Quantity:quantities[item],
            Orders_ID:res.data.value[0].ID,
            Products_ID: parseInt(item)
          };
          
    
          await axios.post('https://sihaadmin.stechomeyazilim.info:8182/addOrderDetailDemir/send', newDetail) .then(async(res) => {
          
         
          })
          
        })

        let authUser = JSON.parse(localStorage.getItem("authUser"));
    
        if(authUser.Users_Type_ID == 1){
          dispatch(onGetReservation(null))
              
        }else{
           dispatch(onGetReservation(authUser.ID));
        }


        toggle();
        setAmountPrice(null)
        setQuantities({})
        setCategory([])
        setCategoryFilter([])
        setSelectedValuesProduct([])
        setstep(0)
        setActiveTab(1)
        setUserID(null)
        setPaymentID(null)
    
      }else{
        showToast('Sıha Otomasyon', "Bir Hata!", false)
      }
   //   showToast('Epilepsi Eğitim', "Şifreniz Başarıyla Değiştirildi!", 'success')

    })
  }
  
  }

  const toggleTab = tab => {

    if(tab > 0){

      if (activeTab !== tab) {
        const modifiedSteps = [...passedSteps];
        modifiedSteps.push(tab);
        setActiveTab(tab);
        setPassedSteps(modifiedSteps);
      }

    if(tab == 2){

    

    /*  var localDate = moment().local();

      const newUser = {
        Users_ID:getUserID,
        Payment_Select_ID:getPaymentID,
        Created_DateTime:localDate,
        //Orders_TotalPrice:values["price"],
        Orders_IsActive:getIsActive,
        //Payment_Type:getPaymentID.toString()
      };

      axios.post('https://sihaadmin.stechomeyazilim.info:8182/addOrderDemir/send', newUser) .then((res) => {

      console.log("lmsdşl",res.data.value)

      setOrderID(res.data.value[0].ID)

      if(res.data.value.length >0){
       
  
      }
     

      }) */
      
    }
    

  }
  };


  const toggleTabEdit = (tab,itemm) => {

    console.log("1231121",tab,getPaymentID)

    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps];
      modifiedSteps.push(tab);
      setActiveTab(tab);
      setPassedSteps(modifiedSteps);
    }

    if(tab > 0){
    if(tab == 2){

    

    /*  var localDate = moment().local();

      const newUser = {
        Users_ID:getUserID,
        Payment_Select_ID:getPaymentID,
        Created_DateTime:localDate,
        //Orders_TotalPrice:values["price"],
        Orders_IsActive:getIsActive,
        //Payment_Type:getPaymentID.toString()
      };

      axios.post('https://sihaadmin.stechomeyazilim.info:8182/addOrderDemir/send', newUser) .then((res) => {

      console.log("lmsdşl",res.data.value)

      setOrderID(res.data.value[0].ID)

      if(res.data.value.length >0){
        
  
      }
     

      }) */
      
    }
    

  }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
    
    },
    validationSchema: Yup.object({



    }),
    onSubmit: values => {
      var localDate = moment().local();

      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Users_ID:getUserID,
          Payment_Select_ID:getPaymentID,
          Orders_TotalPrice:values["price"],
          Orders_IsActive:getIsActive,
          TotalPrice:getCategoryID,
          Quantity:step,
          Orders_ID:null,
          Products_ID: getCategoryID
        };
        dispatch(onUpdateProduct(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Users_ID:getUserID,
          Payment_Select_ID:getPaymentID,
          Created_DateTime:localDate,
          //Orders_TotalPrice:values["price"],
          Orders_IsActive:getIsActive
        };

      
        dispatch(onAddProduct(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [step, setstep] = useState(0)
  const [getIsActive, setIsActive] = useState(false);


  const { users } = useSelector(state => ({
    users: state.contacts.reservation,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalModal, setModalModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [getOrderDetail, setOrderDetail] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);
  const [getCategoryFilter, setCategoryFilter] = useState([]);

  const [getPayment, setPayment] = useState([]);
  const [getUser, setUser] = useState([]);

  const [getType, setType] = useState(false);

  const [getBrand, setBrand] = useState([]);
  const [getUserType, setUserType] = useState(null);
  const [getSubCategory, setSubCategory] = useState([]);

  
  
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
    
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Ürünler",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
          
         { cellProps.Orders_Detail != null ? <div className="">
                 <span className="">
                

                  {cellProps.Orders_Detail.map((item,index) => (
 
 <div className="d-flex"  key={index}>
   
              <a>{item.Products.Products_Name} /</a>
              <a>{item.Products.Product_SubSubCategory.Product_SubSubCategory_Text}</a>

                           </div>
                         ))}

                  
                </span>
              </div> :  null}
          
          </>
        ),
      },


      {
        Header: "Ürün Adeti",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
          
         { cellProps.Orders_Detail != null ? <div className="">
                 <span className="">
                  {cellProps.Orders_Detail.length}
                </span>
              </div> :  null}
          
          </>
        ),
      },
      
      {
        Header: "Tarihi",
        accessor: "Created_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Vade Tarihi",
        accessor: "Created_DateTime1",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Toplam FİYAT",
        accessor: "Orders_TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kullanıcı",
        accessor: "Users.Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Ödeme Tipi",
        accessor: "Payment_Select.Payment_Select_Text",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

<Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick2(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="tooltip" />
                <UncontrolledTooltip placement="top" target="tooltip">
                  Detay
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );
  
  const _getSubSubData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getProductSubSubCategoryDemir/select/`)
      .then((res) => {
        setSubCategory(res.data)
      })
  }

  const _getBrandData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getProductBrandDemir/select/`)
      .then((res) => {
        setBrand(res.data)
      })
  }

  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getProductsDemir/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  const _getPaymentType = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getPaymentSelectDemiryildiz/select/`)
      .then((res) => {
        setPayment(res.data)
      })
  }
  
  const _getProductCategory = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getProductCategoryDemiryildiz/select/`)
      .then((res) => {
        setProductCategory(res.data)
      })
  }
  
  const [getFilterCheck, setFilterCheck] = useState(false);
  const [getFilterModal, setFilterModal] = useState(false);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);
  const [getSelectedValuesProduct, setSelectedValuesProduct] = useState([]);
  
  const [getFilterData, setFilterData] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [totalCashPrice, setTotalCashPrice] = useState(0);
  const [getPaymentType, setPaymentType] = useState(null);
  const [getProductCategory, setProductCategory] = useState([]);
  const [getFilterCategoryData, setFilterCategoryData] = useState([]);

  
  
  useEffect(() => {
    // Seçilen ürünlerin toplam fiyatını hesapla
    const total = getSelectedValuesProduct.reduce((sum, selectedID) => {
      const selectedItem = getCategory.find(item => item.ID == selectedID);
      const quantity = quantities[selectedID] || 1;
      return sum + (selectedItem ? selectedItem.Product_CashPrice * quantity : 0);
    }, 0);
    setTotalCashPrice(total);
  }, [getSelectedValuesProduct, quantities, getCategory]);

  
  const _getAuthData2 = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersDemir/select/`)
      .then((res) => {
        setUser(res.data)
      })
  }

  
  useEffect(() => {
   
    _getSubSubData()
    
 
  }, [getSubCategory]);

  useEffect(() => {
   
    _getProductCategory()
    
 
  }, [getProductCategory]);

  useEffect(() => {
   
    _getBrandData()
    
 
  }, [getBrand]);


  useEffect(() => {
   
    _getAuthData()    
 
  }, [getUser]);

  useEffect(() => {
 
    if (users && !users.length) {

      let authUser = JSON.parse(localStorage.getItem("authUser"));

      setUserType(authUser.Users_Type_ID)
      if(authUser.Users_Limit.length > 0){
        console.log("şmşdf",authUser.Users_Limit[0].Limit_Price)
        setUserLimit(authUser.Users_Limit[0].Limit_Price)
        setUserLimitID(authUser.Users_Limit[0].ID)

      }
      setUserID(authUser.ID)

      console.log("şlmsdf",authUser.Users_Type_ID)

      if(authUser.Users_Type_ID == 1){
        dispatch(onGetReservation(null))
            }else{
         dispatch(onGetReservation(authUser.ID));
      }

     
      setIsEdit(false);
    }
  }, [dispatch, users ]);

  useEffect(() => {
    _getPaymentType()
    
   
  }, [dispatch, getPayment ]);

  useEffect(() => {
    
    _getAuthData2()
    
   
  }, [dispatch, getUser ]);
  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleModal = () => {
    setModalModal(!modalModal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };

  
    
  const handleUserClick2 = (arg) => {
    
    console.log("şmsdşlfi",arg)
    setOrderDetail(arg.Orders_Detail)
    setTotalCashPrice(arg.Orders_TotalPrice)
    setPaymentType(arg.Payment_Select.Payment_Select_Text)

    toggleModal();
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

   
    setIsEdit(true);
    setUserID(user.Users_ID)
    setPaymentID(user.Payment_Select_ID)
    //setSelectedOption(event.target.id);
    
    if(user.Orders_Detail.length >0){

      

      user.Orders_Detail.map((item, index) => {
    
        console.log("lşmslşdf",item.Products_ID)
        setCategoryID(item.Products_ID)
     //   setSelectedValuesProduct()
    }) 
    

        
      setSelectedOption(user.Orders_Detail[0].Products.Product_Brand_ID)
    }
    setIsActive(user.Orders_IsActive)



    console.log("ksmdkfşl",user.Orders_Detail)
   user.Orders_Detail.map((item, index) => (
    
    setQuantities({
      ...quantities,
      [item]: Number(item.Quantity)
    })

    )) 


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteReservation(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"


    
    const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
      elt.NameSurname,
      elt.Last_Login_Date,
        ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
    ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };


    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }

    const exportToCSVLoginLastMonth= () => {


      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      console.log("lksdklf")
      let fileName = getSelectDate + getSelectDateFinish
  
      const data2 = users.filter(x => x.Created_DateTime >= getSelectDate && x.Created_DateTime <= getSelectDateFinish).map(elt => [
      elt.Orders_TotalPrice,
      elt.Payment_Select.Payment_Select_Text,
      moment(elt.Created_DateTime).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Tutar", t: "s" };
        ws["B1"] = { v: "Ödeme Tipi", t: "s" };
        ws["C1"] = { v: "Tarih", t: "s" };

        
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }
    const exportToCSVDontLoginLastMonth= () => {


      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      let fileName = "Son1AyGirisYapmayanlar"
  
  
      const data2 = users.filter(x => x.Last_Login_Date < getSelectDate || x.Last_Login_Date > getSelectDateFinish).map(elt => [
      elt.NameSurname,
      moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
        ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
      
      
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }
    const [getUserID, setUserID] = useState(null);
    const [getPaymentID, setPaymentID] = useState(null);
    const [getUserLimit, setUserLimit] = useState(null);
    const [getUserLimitID, setUserLimitID] = useState(null);

    
    const onClickExcelLogin = (order) => {
      setExcelModal(true);
      setType('login')
    }

    const exportToCSVLogin = () => {
  
      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      let fileName = "GirisYapmayanlar"
  
    const data2 =  users.filter(x=> x.Last_Login_Date != null).map(elt=> [
      elt.NameSurname,
      moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
        ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
      
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }

    const total = getFilterCheckModal == true ? getFilterData.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0) : users.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0);

    
  const _setChoiceUser = arg => {
    
    const [id, limitPrice] = arg.target.value.split('|');

    setUserID(`${id}|${limitPrice}`)

    setUserLimit(`${limitPrice}`)

    if(totalCashPrice > limitPrice){
      showToast('Sıha Otomasyon', "Sipariş Kullanıcının Limitini Geçiyor!", false)

    }else{
      showToast('Sıha Otomasyon', "Sipariş Tutarı Kullanıcının Limiti Geçmiyor", true)

    }

  }


  function showToast(type,message,type2) {

    let ele = null
  
     if(type2 == true){
       ele = "success"
       message = message
    } else{
       ele = "error"
       message = message 
    };
    const position = ["toast-top-right"];
    let toastType;
    const title = "Başlık";
  
  
    //Close Button
    const closeButton = true;
  
    //Debug
    const debug = false;
  
    //Progressbar
    const progressBar = true;
  
    //Duplicates
    const preventDuplicates = false;
  
    //Newest on Top
    const newestOnTop = true;
  
    //position class
    let positionClass = "toast-top-right";
  
    //Show Easing
    const showEasing = "swing";
  
    //Hide Easing
    const hideEasing = "linear";
  
    //show method
    const showMethod = "fadeIn";
  
    //Hide method
    const hideMethod = "fadeOut";
  
    //show duration
    const showDuration = 300;
  
    //Hide duration
    const hideDuration = 1000;
  
    //timeout
    const timeOut = 5000;
  
    //extended timeout
    const extendedTimeOut = 1000;
  
    //Fetch checked Type
   
     toastType = ele;
    
  
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: closeButton,
      debug: debug,
      progressBar: progressBar,
      preventDuplicates: preventDuplicates,
      newestOnTop: newestOnTop,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  
    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }


  const _setChoicePaymentType = arg => {
    setPaymentID(arg.target.value)
  }
  
  const _setChoiceCategory = (event) => {

    try {

      const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesProduct(selectedOptions); // State güncelleniyor

  } catch (err) {
    console.log("err",err)
    }

  
  };

  const handleQuantityChange = (id, value) => {
    setQuantities({
      ...quantities,
      [id]: Number(value)
    });
  };

  const [activeTab, setActiveTab] = useState(1);
  const [getCheckFilter, setCheckFilter] = useState(false);

  
  function filterOrdersBySubSubCategory(subSubCategoryID) {
    return users.map(order => {
      const filteredDetails = order.Orders_Detail.filter(
        detail => detail.Products.Product_SubSubCategory.Product_SubCategory.Product_Category.ID === subSubCategoryID
      );
      return {
        ...order,
        Orders_Detail: filteredDetails
      };
    }).filter(order => order.Orders_Detail.length > 0);
  }
  
  
  // Kullanım örneği:

  const _setFilterProduct = (val) => {
  

    if(val=="all"){
      setCheckFilter(false)
    }else{


      setCheckFilter(true)

      const filteredOrders = filterOrdersBySubSubCategory(val);

      setFilterCategoryData(filteredOrders)

      console.log("filteredOrders123",filteredOrders);

    }
  
 
  }
  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 
  }

  const [selectedOption, setSelectedOption] = useState(null);

  
  const _handleChange = (event) => {
    try {

    setSelectedOption(event.target.id);

    const data2 =  getCategory.filter(x=> x.Product_SubSubCategory_ID == event.target.id)

    setCategoryFilter(data2)

  } catch (err) {
    console.log(err)
    }
    
  };
  const [getAmountPrice, setAmountPrice] = useState('');


  const handleInputChange = (event) => {
    const { value } = event.target;
    setTotalCashPrice(total);
    setAmountPrice(value)
};

  return (
    <React.Fragment>
    

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={getType == "login" ? exportToCSVLoginLastMonth: exportToCSVDontLoginLastMonth}
        show={getExcelModal}
        type={getType}
        onCloseClick={() => setExcelModal(false)}
      />
      

      <FilterModal
        handleSelectChangeCompany= {_onFilterClick}
        show={getFilterModal}
        getCategoryCompany={getUser}
        onCloseClick={() => setFilterModal(false)}
     
      />

         
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Siparişler" />

  <div className="d-flex">

  <Button
    type="button"
    color="danger"
    className="btn-rounded mb-2 me-2"
    onClick={() => _setFilterProduct("all")}
  >
    <a>Hepsi</a>
  </Button>

{getProductCategory.map((item,index) => (
 
  <div className="d-flex"  key={index}>
     <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> _setFilterProduct(item.ID)}
              >
               <a>{item.Product_Category_Name}</a>
                
              </Button>

                         
                            </div>
                          ))}</div>

<div className="d-flex">
{  getUserType == 1 && getFilterCheckModal == false ? 
          <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterModal(true)}
              >
                <i className="mdi mdi-plus me-1" />
                Firmaya Göre Filtrele
              </Button>:
              getUserType == 1 ?
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterCheckModal(false)}
              >
                <i className="mdi mdi-plus me-1" />
                Listeyi Sıfırla
              </Button> : null}

          <ExportToExcel2 title={"Tarihe Göre Excel"} onDownloadClick={exportToCSVLoginLastMonth} onClickExcel={onClickExcelLogin} apiData={users} fileName={"fileName"} />
          </div>

          <div className="d-flex">
            <div className="m-1">
            <Label className="form-label text-success">Toplam Tutar: {total} TL</Label>
          </div>
          <div className="m-1">
          <Label className="form-label">Vadesi Gelmemiş Tutar : {total} TL</Label>

          </div>

          <div className="m-1">
          <Label className="form-label text-danger">Vadesi Geçmiş Tutar : {total} TL</Label>
          </div>
          </div>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <TableContainer
                    columns={columns}
                    data={getCheckFilter == true ? getFilterCategoryData : getFilterCheckModal == true ? getFilterData : getFilterCheck == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddOrder={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

        

                  <Modal size="xl" isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                    {isEdit ? (
  "Düzenle"
) : (
  <>
    {getUserType == 2 ? (
      `Sipariş Ekle ${getUserLimit} TL Limitiniz Var`
    ) : (
      `Sipariş Ekle`
    )}
  </>
)}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

<div id="kyc-verify-wizard" className="wizard clearfix">
                          <div className="steps clearfix">
                            <ul>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 1,
                                  })}
                                  onClick={() => {
                                    toggleTab(1);
                                  }}
                                  disabled={!passedSteps.includes(1)}
                                >
                                  <span className="number">1.</span>
                                Ürün Bilgileri
                                </NavLink>
                              </NavItem>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 2,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 2,
                                  })}
                                  onClick={() => {
                                    toggleTab(2);
                                  }}
                                  disabled={!passedSteps.includes(2)}
                                >
                                  <span className="number">2.</span>
                                  Sipariş Bilgileri
                                </NavLink>
                              </NavItem>
                           
                            </ul>
                          </div>
                          <div className="content clearfix">
                            <TabContent
                              activeTab={activeTab}
                              className="twitter-bs-wizard-tab-content"
                            >
                              <TabPane tabId={2} id="personal-info">
                                <Form  onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup className="mb-3">
                                    
                        <Label className="form-label">Ödeme Tipini Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          defaultValue={getPaymentID}
                          onChange={(text) => _setChoicePaymentType(text)}
                          value={getPaymentID} // Change this line
                        >
                            <option value="">Lütfen Seçim Yapınız</option>

                            selectedOption != null && selectedOption == "Tahsilat" 

                          {selectedOption == "Tahsilat"  ? getPayment.filter((x)=>x.Payment_Select_Text == "Çek" || x.Payment_Select_Text == "Senet").map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Payment_Select_Text}</option>
                          )) : getPayment.filter((x)=>x.Payment_Select_Text == "Eft" || x.Payment_Select_Text == "Kart").map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Payment_Select_Text}</option>
                          ))}

                        </Input>
                        {validation.touched.wsText && validation.errors.wsText ? (
                          <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                        ) : null}
                    
                                      </FormGroup>
                                    </Col>
                                    {getUserType == 1 ?  
                                    <Col lg="6">
                                      <FormGroup className="mb-3">
                                  
                        <Label className="form-label">Kullanıcı Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          defaultValue={getUserID}
                          onChange={(text) => _setChoiceUser(text)}
                          value={getUserID} // Change this line
                        >
                            <option value="">Lütfen Seçim Yapınız</option>

                          {getUser.map((item, index) => (
                            <option  value={`${item.ID}|${item.Users_Limit.length > 0 ? item.Users_Limit[0].Limit_Price : ''}`} key={item.ID}>{item.Users_NameSurname} - {item.Users_Limit.length > 0 ? item.Users_Limit[0].Limit_Price : null} TL</option>
                          ))}

                        </Input>
                        {validation.touched.wsText && validation.errors.wsText ? (
                          <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                        ) : null}
                   
                                      </FormGroup>
                                    </Col> : null}
                                    
                   
                  
                                    <Col lg={6}>
                                        <div className="mb-3">
                                          
             {/**          <div className="square-switch">

<input
  type="checkbox"
  id="square-switch1"
  className="switch"
  defaultChecked={getIsActive}
  onChange={() =>
    setIsActive(!getIsActive)
  }
/>
<label
htmlFor="square-switch1"
  data-on-label="Aktif "
  data-off-label="Pasif"
/>
</div>*/}

                                        </div>
                                      </Col>
                                  </Row>

                            
                                </Form>
                              </TabPane>
                              <TabPane tabId={1} id="confirm-email">
                                <div>
                                  <Form>
                                    <Row className="d-flex">

                                    <Col xl="2">
                      <div className="control-group" id="toastTypeGroup">
                        <div className="controls mb-4">
                          <Label>Ürün Kategorisi</Label>

                          {getSubCategory.map((option) => (
         <div className="mb-3" key={option.ID}>
          <Input
            type="radio"
            id={option.ID}
             name="radioStatus"
            className="form-check-input"
            value={selectedOption}
            defaultValue={selectedOption}
            onChange={_handleChange}
          />
          <label className="form-check-label" htmlFor={option.ID}>
            {option.Product_SubSubCategory_Text}
          </label>
        </div>
      ))}


<div className="mb-3" key={"Tahsilat"}>
          <Input
            type="radio"
            id={"Tahsilat"}
             name="radioStatus"
            className="form-check-input"
            value={selectedOption}
            defaultValue={selectedOption}
            onChange={_handleChange}
          />
          <label className="form-check-label" htmlFor={"Tahsilat"}>
            Tahsilat
          </label>
        </div>

                    
                        </div>
                      </div>
                      </Col>
                

                      <Col xl="6">
                      
                      {selectedOption != null && selectedOption != "Tahsilat"   ? <div className="mb-3">
                        <Label className="form-label">Ürün Seçiniz</Label>
                        <Input
        name="paymentStatus"
        type="select"
        multiple
        className="form-select"
        defaultValue={getCategoryID}
        onChange={_setChoiceCategory}
        value={getSelectedValuesProduct} // Değiştirilen satır
      >

                          {getCategoryFilter.length == 0 ?  <option value="">Ürün Bulunmamaktadır</option> :
                          getCategoryFilter.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Products_Name + " " + item.Products_StockCode + " " + item.Product_CashPrice + " TL"}</option>
                          ))}

                        </Input>
                    
                      </div> :  selectedOption != null && selectedOption == "Tahsilat"   ? 
                       <div className="mb-3 m-2">
                       <Label htmlFor="metatitle">Tahsilat Tutarını Giriniz</Label>
       <input
         type="number"
         name="number"
         className="form-control"
                     placeholder="Tahsilat Tutarı"
                     onChange={handleInputChange}
                     value={getAmountPrice}

       />
       </div>
                      : <div className="mb-3">
                      <Label className="form-label text-danger">Lütfen Ürün Tipi Seçiniz</Label>
                      </div>}
                      </Col>

                      <Col xl="4">

{isEdit == true ? getSelectedValuesProduct.map((selectedID) => {
      const selectedItem = getCategory.find(item => item.ID == selectedID.Products_ID);

      return (
        <Col lg={12} key={selectedID}>
          <div className="mb-3">
            <Label className="form-label">{selectedItem ? selectedItem.Products_Name : 'Ürün'}</Label>
            <Input
              type="number"
              className="form-control"
              value={quantities[selectedID]}
              onChange={(e) => handleQuantityChange(selectedID, e.target.value)}
              min="1"
            />

<Label className="form-label">{parseInt(selectedItem.Product_CashPrice) * parseInt(quantities[selectedID])} TL</Label>
          </div>
        </Col>
      );
    }) : getSelectedValuesProduct.map((selectedID) => {
      const selectedItem = getCategory.find(item => item.ID == selectedID);

      return (
        <Col lg={12} key={selectedID}>
          <div className="mb-3">
            <Label className="form-label">{selectedItem ? selectedItem.Products_Name : 'Ürün'}</Label>
            <Input
              type="number"
              className="form-control"
              value={quantities[selectedID]}
              onChange={(e) => handleQuantityChange(selectedID, e.target.value)}
              min="1"
            />

<Label className="form-label">{parseInt(selectedItem.Product_CashPrice) * parseInt(quantities[selectedID])} TL</Label>
          </div>
        </Col>
      );
    })}

                                 
                                  </Col>

                                     
                                      </Row>
                                      
                                      {selectedOption != "Tahsilat"  ?  
                                      <Row>
                                      <Label className="form-label">Toplam Fiyat : {totalCashPrice} TL</Label>
                                    </Row>: null}
                                 
                                  </Form>
                                </div>
                              </TabPane>
                           
                            </TabContent>
                          </div>
                          <div className="actions clearfix">
                            <ul role="menu" aria-label="Pagination">
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab - 1);
                                  }}
                                >
                                  Geri
                                </Link>
                              </li>
                              {activeTab === 2 ? 
                                  
                                  totalCashPrice > getUserLimit ? 
                                   <Link
                                  to="#"
                                  onClick={() => {
                                    showToast('Sıha Otomasyon', "Sipariş Kullanıcının Limitini Geçiyor!", false)
                                  }}
                                >
                                    Toplam Sepet Tutarınız Limitin Üstünde
                                   </Link> : 
                                 <Link
                                 to="#"
                                 onClick={() => {
                                   submitAdd(activeTab - 1);
                                 }}
                               >
                                   {totalCashPrice} TL Sepeti Kaydet
                                  </Link>
                                 : 
                                 <li
                                className={
                                  activeTab === 2 ? "next disabled" : "next"
                                }
                              >
                                {isEdit == true ? 
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTabEdit(activeTab + 1);
                                  }}
                                >
                                  Siparişi Düzenle Ve Ürün Detaylarını Gir
                                </Link> : 
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab + 1);
                                  }}
                                >
                                  Sipariş Bilgilerini Gir
                                </Link>}

                              </li>}
                            </ul>
                          </div>
                        </div>

                      
                        <Row>



                        
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>


                

                  <Modal size="xl"  isOpen={modalModal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal} tag="h4">
                      {!!isEdit ? "Detay" : {getPaymentType} + " Ödemeli " + {totalCashPrice} + " TL Total Ücret"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                       

                      {getOrderDetail.map((item, index) => 
                         { 
                          return(
                            <Row  key={index}>

<div className="d-flex gap-3">

<Col >

                        
                           <Label className="form-label">
                           Ürün Bilgileri                           

                           </Label>

                     
                         
                           </Col>

                           <Col>

                        
                           <Label className="form-label text-success">
                           Sipariş Bilgileri              

                           </Label>

                     
                         
                           </Col>

                           <Col>

                        
                           <Label className="form-label">
                           Ürün Görseli                     

                           </Label>

                     
                         
                           </Col>
</div>

                          <div className="d-flex gap-3">
                            <Col >

                        
                           <Label className="form-label">
                            {item.Products.Products_Name} -                              {item.Products.Product_SubSubCategory.Product_SubSubCategory_Text}

                           </Label>

                     
                         
                           </Col>

                           <Col >
                           <Label className="form-label text-success">
                            {item.Quantity} Adet
                           </Label>

                           <Label className="form-label text-success">
                            - {item.TotalPrice} TL
                           </Label>
                           </Col>
                           
                           <Col >
                           <div>
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + item.Products.Products_Image} alt={index} className="avatar-xl" />
                            </div>
                            
                            </Col>
                            </div>


                         
                         
                          </Row>
                        )})}
                    
                       
                        
                       
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
